import ApiService from "@/common/api.service";
import {
  LOGIN,
  LOGOUT,
  REGISTER_ACCOUNT_EVENTKEY,
  CHECK_AUTH,
  SET_PASSWORD_AUTHCODE,
  CHECK_AUTHCODE,
  FETCH_PREREGISTRATION_AUTHCODE,
  FETCH_PREREGISTRATION_USER,
  UPDATE_PREREGISTRATION_AUTHCODE,
  UPDATE_PREREGISTRATION_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  RESET_PASSWORD_AVAILABLE,
  RC_LOGOUT,
  GET_CONFIG,
  GET_CHECKININFO
} from "./actions.type";
import {
  HIDE_FLOATY, SET_AUTHCODE_RESULT, SET_LIVECHATENABLED,
  SET_AUTH, PURGE_AUTH, SET_ERROR, SET_PASSWORD_SUCCESS,
  SET_RESET_PASSWORD_AVAILABLE,
  SET_MSG, UNSET_MSG,
  UNSET_ERROR, SET_CONFIG, SET_CONFIGUPDATEDAT, SET_CHECKININFO,
  SET_UNREADCHATROOMS
} from "./mutations.type";

const state = {
  errors: null,
  msg: null,
  user: {},
  isAuthenticated: false,
  eventConfig: null,
  liveChatEnabled: false,
  isVerified: null,
  needPassword: null,
  isValidAuthCode: null,
  configUpdatedAt: null,
  userUpdatedAt: null,
  checkinCode: null,
  checkinAt: null,
  unreadChatRooms: null,
  offline: false,
  preregistration: null,
  preregistrationEnabled: null,
  preregistrationStart: null,
  preregistrationEnd: null,
  preregistrationError: null,
  preregistrationIsValidAuthCode: null,
  resetPasswordAvailable: null,
  timezone: null,
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  timezone(state) {
    return state.timezone;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  eventConfig(state) {
    return state.eventConfig;
  },
  isVerified(state) {
    return state.isVerified;
  },
  liveChatEnabled(state) {
    return state.liveChatEnabled;
  },
  needPassword(state) {
    return state.needPassword;
  },
  isValidAuthCode(state) {
    return state.isValidAuthCode;
  },
  configUpdatedAt(state) {
    return state.configUpdatedAt;
  },
  userUpdatedAt(state) {
    return state.userUpdatedAt;
  },
  checkinCode(state) {
    return state.checkinCode;
  },
  checkinAt(state) {
    return state.checkinAt;
  },
  unreadChatRooms(state) {
    return state.unreadChatRooms;
  },
  offline(state) {
    return state.offline;
  },
  preregistration(state) {
    return state.preregistration;
  },
  preregistrationStart(state) {
    return state.preregistrationStart;
  },
  preregistrationEnd(state) {
    return state.preregistrationEnd;
  },
  preregistrationEnabled(state) {
    return state.preregistrationEnabled;
  },
  errors(state) {
    return state.errors;
  },
  preregistrationError(state) {
    return state.preregistrationError;
  },
  preregistrationIsValidAuthCode(state) {
    return state.preregistrationIsValidAuthCode;
  },
  msg(state) {
    return state.msg;
  },
  resetPasswordAvailable(state) {
    return state.resetPasswordAvailable;
  }

};

const actions = {
  [LOGIN](context, payload) {
    return new Promise(resolve => {
      ApiService.post("auth/login?lang=" + payload.lang, { user: payload.credentials })
        .then(({ data }) => {
          context.commit(SET_AUTH, data.user);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [SET_PASSWORD_AUTHCODE](context, payload) {
    const { authCode, newPassword, newPasswordVerify } = payload;
    const user = {
      authCode, newPassword, newPasswordVerify
    };
    return new Promise(resolve => {
      ApiService.post("auth/setPasswordWithAuthCode", user)
        .then(({ data }) => {
          context.commit(SET_PASSWORD_SUCCESS, true);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [RESET_PASSWORD](context, payload) {
    const { token, newPassword, newPasswordVerify } = payload;
    const user = {
      token, newPassword, newPasswordVerify
    };
    return new Promise((resolve, reject) => {
      ApiService.post("auth/resetPassword?lang=" + payload.lang, user)
        .then(({ data }) => {
          context.commit(SET_PASSWORD_SUCCESS, true);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.messages);
          reject(response);
        });
    });
  },
  [RESET_PASSWORD_AVAILABLE](context, payload) {
    const { token } = payload;
    const user = {
      token
    };
    return new Promise((resolve, reject) => {
      ApiService.post("auth/resetPasswordAvailable?lang=" + payload.lang, user)
        .then(({ data }) => {
          context.commit(SET_RESET_PASSWORD_AVAILABLE, true);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_RESET_PASSWORD_AVAILABLE, false);
          context.commit(SET_ERROR, response.data.messages);
          reject(response);
        });
    });
  },
  [FORGOT_PASSWORD](context, payload) {
    // console.log(payload);
    const { email } = payload.credentials;
    const user = {
      email
    };
    return new Promise((resolve, reject) => {
      ApiService.post("auth/forgotPassword?lang=" + payload.lang, user)
        .then(({ data }) => {
          console.log(data);
          context.commit(SET_PASSWORD_SUCCESS, true);
          context.commit(SET_MSG, data.msg);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.messages);
          reject(response);
        });
    });
  },
  [CHECK_AUTHCODE](context, payload) {
    const { authCode } = payload;
    const user = {
      authCode
    };
    return new Promise(resolve => {
      ApiService.post("auth/checkAuthCode", user)
        .then(({ data }) => {
          context.commit(SET_AUTHCODE_RESULT, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [FETCH_PREREGISTRATION_USER](context, payload) {
    return new Promise(resolve => {
      ApiService.get("auth", "getPreregistration")
        .then(({ data }) => {
          context.commit('setPreregistrationResult', data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit('setPreregistrationError', true);
        });
    });
  },
  [FETCH_PREREGISTRATION_AUTHCODE](context, authcode) {
    return new Promise(resolve => {
      ApiService.post("auth/getPreregistrationWithAuthCode", authcode)
        .then(({ data }) => {
          context.commit('setPreregistrationResult', data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit('setPreregistrationError', true);
        });
    });
  },
  [UPDATE_PREREGISTRATION_USER](context, payload) {
    return new Promise(resolve => {
      ApiService.post("auth/setPreregistration", { 'preregistration': payload })
        .then(({ data }) => {
          context.commit('setPreregistrationResultSimple', payload);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit('setPreregistrationError', true);
        });
    });
  },
  [UPDATE_PREREGISTRATION_AUTHCODE](context, payload) {
    return new Promise(resolve => {
      ApiService.post("auth/setPreregistrationWithAuthCode", payload)
        .then(({ data }) => {
          context.commit('setPreregistrationResultSimple', payload.preregistration);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit('setPreregistrationError', true);
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
    ApiService.get("auth", "logout")
      .then(({ data }) => {
      })
      .catch(({ response }) => {
        //context.commit(SET_ERROR, response.data.errors);

      });
  },
  [REGISTER_ACCOUNT_EVENTKEY](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("auth/createAccountEventKey?lang=" + payload.lang, { user: payload.credentials })
        .then(({ data }) => {
          if (typeof data.user != "undefined") {
            // If login information are sent on create account, login immediately.
            // Used for anonymous login.
            context.commit(SET_AUTH, data.user);
          }
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [GET_CONFIG](context) {
    ApiService.get("auth", "getConfig")
      .then(({ data }) => {
        context.commit(SET_CONFIG, data.config);
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.errors);
      });
  },
  [GET_CHECKININFO](context) {
    ApiService.get("auth", "getCheckinInfo")
      .then(({ data }) => {
        context.commit(SET_CHECKININFO, data);
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.errors);
      });
  },
  [CHECK_AUTH](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.getWParam("auth/ping", {
        ...params, "configUpdatedAt": context.state.configUpdatedAt,
        "userUpdatedAt": context.state.userUpdatedAt,
      })
        .then(({ data }) => {
          context.commit(SET_AUTH, data.user);
          context.commit(SET_CONFIGUPDATEDAT, data)
          context.commit(SET_UNREADCHATROOMS, data.unreadChatRooms)
          resolve(data);
        })
        .catch(({ response }) => {
          //context.commit(SET_ERROR, response.data.errors);
          context.commit(PURGE_AUTH);
          resolve();
        });
    })
  },
  offline(context) {
    context.commit('setOffline');
  },
  online(context) {
    context.commit('setOnline');
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [UNSET_ERROR](state) {
    state.errors = null;
  },
  [SET_MSG](state, msg) {
    state.msg = msg;
  },
  [UNSET_MSG](state) {
    state.msg = null;
  },
  [SET_AUTH](state, user) {
    state.user = user;
    state.errors = null;
    state.isAuthenticated = true;
  },
  [SET_AUTHCODE_RESULT](state, data) {
    state.isVerified = data.isVerified;
    state.needPassword = data.needPassword;
    state.isValidAuthCode = data.isValidAuthCode;
  },
  [SET_CONFIG](state, config) {
    state.eventConfig = config;
  },
  [SET_CHECKININFO](state, config) {
    state.checkinCode = config.checkin_code;
    state.checkinAt = config.checkin_at;
  },
  [SET_LIVECHATENABLED](state, data) {
    state.liveChatEnabled = data;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.configUpdatedAt = null;
    state.user = {};
    state.errors = {};
  },
  [SET_PASSWORD_SUCCESS](state, value) {
    state.setPasswordSuccess = value;
  },
  [SET_RESET_PASSWORD_AVAILABLE](state, value) {
    state.resetPasswordAvailable = value;
  },
  [SET_CONFIGUPDATEDAT](state, value) {
    if (state.configUpdatedAt !== value.configUpdatedAt) {
      state.eventConfig = value.config;
      state.configUpdatedAt = value.configUpdatedAt;
    }
  },
  [SET_UNREADCHATROOMS](state, value) {
    state.unreadChatRooms = value;
  },
  setOnline(state) {
    state.offline = false;
  },
  setOffline(state) {
    state.offline = true;
  },
  setTimezone(state, timezone) {
    if (timezone == null) {
      state.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    } else {
      state.timezone = timezone;
    }
  },
  setPreregistrationResult(state, val) {
    state.preregistration = val.preregistration;
    state.preregistrationStart = val.start;
    state.preregistrationEnd = val.end;
    state.preregistrationEnabled = val.enabled;
    state.preregistrationIsValidAuthCode = val.isValidAuthCode;
    state.preregistrationError = false;
  },
  setPreregistrationResultSimple(state, val) {
    state.preregistration = val;
    state.preregistrationError = false;
  },
  setPreregistrationError(state, error) {
    state.preregistrationError = error;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
